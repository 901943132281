import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'
import {getCallsByContact, fetchPhonesById} from '../contacts/actions'
import moment from 'moment'

// Action Names
export const FETCH_DAILY_GOAL = 'FETCH_DAILY_GOAL'
export const FETCH_STATUS_LIST = 'FETCH_STATUS_LIST'
export const FETCH_TRAYS = 'FETCH_TRAYS'
export const FETCH_TRAYS_TICKETS = 'FETCH_TRAYS_TICKETS'
export const GET_TICKET_BY_ID = 'GET_TICKET_BY_ID'
export const FETCH_TICKET_HISTORY_LOG = 'FETCH_TICKET_HISTORY_LOG'
export const GET_CURRENT_PROMISE = 'GET_CURRENT_PROMISE'
export const GET_PROMISES_BY_TICKET = 'GET_PROMISES_BY_TICKET'
export const GET_NEXT_TICKET = 'GET_NEXT_TICKET'
export const FETCH_TICKET_STATUS = 'FETCH_TICKET_STATUS'
export const FETCH_STATUS_LIST_BY_TICKET = 'FETCH_STATUS_LIST_BY_TICKET'
export const GET_CATALOG_ITEMS = 'GET_CATALOG_ITEMS'
export const PAYMENT_DELAY_CATALOG = 'PAYMENT_DELAY_CATALOG'
export const PAYMENT_POSTPONED_CATALOG = 'PAYMENT_POSTPONED_CATALOG'
export const TICKET_STATUS_CATALOG = 'TICKET_STATUS_CATALOG'
export const GET_AGENTS_MONITORING = 'GET_AGENTS_MONITORING'
export const MANAGEMENT_COLLECTIONS_REPORT = 'MANAGEMENT_COLLECTIONS_REPORT'
export const LOADER_TICKET = 'LOADER_TICKET'
export const GET_TOTAL_CLIENTS = 'GET_TOTAL_CLIENTS'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_COMPANY = 'SET_COMPANY'
// API URLs
const {XONE_CASH_API, ECOMMERCE_FULFILLMENT_API} = CONSTANTS

export const clearTicketStore = () => async (dispatch) => {
    dispatch({type: GET_TICKET_BY_ID, payload: null})
    dispatch({type: FETCH_TICKET_HISTORY_LOG, payload: null})
    dispatch({type: GET_CURRENT_PROMISE, payload: null})
    dispatch({type: GET_PROMISES_BY_TICKET, payload: null})
}

export const fetchDailyWork = (token, userId = null) => async (dispatch) => {
    const url = `${XONE_CASH_API}/v1/tickets/dailyWork/${userId}`
    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: FETCH_DAILY_GOAL, payload: data})
}

export const fetchStatusList = (ticketId) => async (dispatch) => {
  const headers = await getFulfillmentTokenHeaders()
  // const { data } = await axios.get(`${ECOMMERCE_FULFILLMENT_API}/api/workflows/${CONSTANTS.WORKFLOW_ID}`, { headers })
  const { data } = await axios.get(`${ECOMMERCE_FULFILLMENT_API}/api/workflows/${CONSTANTS.WORKFLOW_ID}`)
  const { steps = null } = data
  dispatch({ type: FETCH_STATUS_LIST, payload: steps || [] })
}

export const fetchStatusListByTicket = (ticketId) => async (dispatch) => {
  const headers = await getFulfillmentTokenHeaders()
  // const { data } = await axios.get(`${ECOMMERCE_FULFILLMENT_API}/api/steps/findByIssue/${ticketId}`, { headers })
  const { data } = await axios.get(`${ECOMMERCE_FULFILLMENT_API}/api/steps/findByIssue/${ticketId}`)
  dispatch({ type: FETCH_STATUS_LIST_BY_TICKET, payload: data || [] })
}

export const fetchTrays = (token, agent, filtersData = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/filters`
    // TODO: cambiar a post y cambiar el mapeo una vez que arregle el back
    if (!!agent && agent.roleName === CONSTANTS.AGENT_ROLE_NAME) {
        url += `?agentId=${agent.id}`
    }
    if (!!filtersData) {
        const {
            customerName,
            companyId,
            minDaysPastDue,
            maxDaysPastDue,
            documentId,
            status,
            agentId,
            postponedPayment,
            accountExecutiveId,
            minDebt,
            maxDebt
        } = filtersData
        if (!!customerName) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `customerName=${customerName}`
        }
        if (!!companyId) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `companyId=${companyId}`
        }
        if (!!minDaysPastDue) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `minDaysPastDue=${minDaysPastDue}`
        }
        if (!!maxDaysPastDue) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `maxDaysPastDue=${maxDaysPastDue}`
        }
        if (!!documentId) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `documentId=${documentId}`
        }
        if (!!status) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `status=${status}`
        }
        if (!!agentId) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `agentId=${agentId}`
        }
        if (!!postponedPayment) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `postponedPaymentReason=${postponedPayment}`
        }
        if (!!accountExecutiveId) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `accountExecutiveId=${accountExecutiveId}`
        }
        if (!!minDebt) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `minDebt=${minDebt}`
        }
        if (!!maxDebt) {
            url += (url.search('\\?') === -1) ? '?' : '&'
            url += `maxDebt=${maxDebt}`
        }
    }
    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: FETCH_TRAYS, payload: data || []})
}

export const getTotalClients = (token, filterName, agent, filtersData = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/totalClients`
    if (!!filterName) {
        url += `?filterName=${filterName}`
        if (!!agent && agent.roleName === CONSTANTS.AGENT_ROLE_NAME) {
            url += `&agentId=${agent.id}`
        }
        if (!!filtersData) {
            const {
                customerName,
                companyId,
                status,
                agentId,
                documentId,
                minDaysPastDue,
                maxDaysPastDue,
                postponedPayment,
                accountExecutiveId,
                minDebt,
                maxDebt
            } = filtersData
            if (!!customerName) {
                url += `&customerName=${customerName}`
            }
            if (!!companyId) {
                url += `&companyId=${companyId}`
            }
            if (!!status) {
                url += `&status=${status}`
            }
            if (!!agentId) {
                url += `&agentId=${agentId}`
            }
            if (!!documentId) {
                url += `&documentId=${documentId}`
            }
            if (!!minDaysPastDue) {
                url += `&minDaysPastDue=${minDaysPastDue}`
            }
            if (!!maxDaysPastDue) {
                url += `&maxDaysPastDue=${maxDaysPastDue}`
            }
            if (!!postponedPayment) {
                url += `&postponedPaymentReason=${postponedPayment}`
            }
            if (!!accountExecutiveId) {
                url += `&accountExecutiveId=${accountExecutiveId}`
            }
            if (!!minDebt) {
                url += `&minDebt=${minDebt}`
            }
            if (!!maxDebt) {
                url += `&maxDebt=${maxDebt}`
            }
        }
    }

    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: GET_TOTAL_CLIENTS, payload: data})
}

export const fetchTraysTickets = (token, filterName, agent, filtersData = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/tray`
    if (!!filterName) {
        url += `?filterName=${filterName}`
        if (!!agent && agent.roleName === CONSTANTS.AGENT_ROLE_NAME) {
            url += `&agentId=${agent.id}`
        }
        if (!!filtersData) {
            const {
                page,
                size,
                customerName,
                companyId,
                status,
                agentId,
                documentId,
                minDaysPastDue,
                maxDaysPastDue,
                postponedPayment,
                accountExecutiveId,
                maxDebt,
                minDebt,
            } = filtersData
            if (!!page) {
                url += `&page=${page}`
            }
            if (!!size) {
                url += `&size=${size}`
            }
            if (!!customerName) {
                url += `&customerName=${customerName}`
            }
            if (!!companyId) {
                url += `&companyId=${companyId}`
            }
            if (!!status) {
                url += `&status=${status}`
            }
            if (!!agentId) {
                url += `&agentId=${agentId}`
            }
            if (!!documentId) {
                url += `&documentId=${documentId}`
            }
            if (!!minDaysPastDue) {
                url += `&minDaysPastDue=${minDaysPastDue}`
            }
            if (!!maxDaysPastDue) {
                url += `&maxDaysPastDue=${maxDaysPastDue}`
            }
            if (!!postponedPayment) {
                url += `&postponedPaymentReason=${postponedPayment}`
            }
            if (!!accountExecutiveId) {
                url += `&accountExecutiveId=${accountExecutiveId}`
            }
            if (!!minDebt) {
                url += `&minDebt=${minDebt}`
            }
            if (!!maxDebt) {
                url += `&maxDebt=${maxDebt}`
            }
        }
    }

    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: FETCH_TRAYS_TICKETS, payload: data || []})
}

export const getTicketDetails = (ticketId, token) => async (dispatch) => {
    const {data} = await axios.get(`${XONE_CASH_API}/v1/tickets/findByTicketId/${ticketId}`, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: GET_TICKET_BY_ID, payload: data})
}

export const fetchTicketHistoryLog = (token, id, pageSettings = null) => async (dispatch) => {
    let pagination = {}
    if (!!pageSettings) {
        pagination = {
            'start': pageSettings.start,
            'number': pageSettings.number,
        }
    } else {
        pagination = {'start': 0, 'number': 5}
    }
    const {data} = await axios.post(
        `${XONE_CASH_API}/v1/ticketHistoryLog/listTicketHistoryLog/`,
        {'pagination': pagination, 'search': {'ticketId': id}, 'sort': {'predicate': 'created_at', 'asc': false}},
        {headers: {'Authorization': 'Bearer ' + token}},
    )

    dispatch({type: FETCH_TICKET_HISTORY_LOG, payload: data})
}

export const getCurrentPromise = (ticketId, token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/tickets/getCurrentPromiseFromTicketId/${ticketId}`,
        {headers: {'Authorization': 'Bearer ' + token}},
    )

    dispatch({type: GET_CURRENT_PROMISE, payload: data})
}

export const getPromisesByTicket = (ticketId, token) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/tickets/getPromisesFromTicketId/${ticketId}`,
        {headers: {'Authorization': 'Bearer ' + token}},
    )

    dispatch({type: GET_PROMISES_BY_TICKET, payload: data})
}

export const getNextTicketByAgent = (token, ticketId, userId = 1) => async (dispatch) => {
    const {data} = await axios.get(
        `${XONE_CASH_API}/v1/tickets/getNextTicketByAgent?ticketId=${ticketId}&agentId=${userId}`,
        {headers: {'Authorization': 'Bearer ' + token}},
    )

    dispatch({type: GET_NEXT_TICKET, payload: data})
}

export const saveForm = (call, ticket, token) => async (dispatch) => {
  try {
    message.loading('Guardando...', 3)
    const headers = await getFulfillmentTokenHeaders()
    await axios.post(`${XONE_CASH_API}/v1/calls/create`, call, { headers: { 'Authorization': 'Bearer ' + token } })
    dispatch(getCallsByContact(token, call.receptor.externalId))
    if (!!ticket) {
      // await axios.put(`${ECOMMERCE_FULFILLMENT_API}/api/issues/${ticket.externalId}`, ticket, { headers })
      await axios.put(`${ECOMMERCE_FULFILLMENT_API}/api/issues/${ticket.externalId}`, ticket)
      dispatch(fetchStatusListByTicket(ticket.externalId))
      // TODO: cambiar esto por sockets
      await timeout(3000)
      dispatch(getTicketDetails(ticket.ticketId, token))
      dispatch(fetchTicketHistoryLog(token, ticket.ticketId))
      dispatch(getCurrentPromise(ticket.ticketId, token))
      dispatch(getPromisesByTicket(ticket.ticketId, token))
    } else {
      await timeout(1000)
    }
    dispatch(fetchPhonesById(token, call.receptor.externalId))
    message.destroy()
    message.success('La gestión ha sido guardada exitósamente')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar la gestión')
  }
}

const getFulfillmentTokenHeaders = async () => {
    const body = {
        grant_type: CONSTANTS.ECOMMERCE_FULFILLMENT_GRANT_TYPE,
        client_id: CONSTANTS.ECOMMERCE_FULFILLMENT_CLIENT_ID,
        client_secret: CONSTANTS.ECOMMERCE_FULFILLMENT_CLIENT_SECRET,
    }

  // const { data } = await axios.post(`${ECOMMERCE_FULFILLMENT_API}/oauth/token`, body)

  // const headers = {
  //   'Authorization': 'Bearer ' + data.access_token,
  // }
  //
  // return headers
  return ''
}

export const transferTicketMultiple = (token, filterName, agent, agentIdTransfer, filtersData = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/transferTicketMultiple`
    if (!!filterName) {
        url += `?filterName=${filterName}`
        if (!!agent && agent.roleName === CONSTANTS.AGENT_ROLE_NAME) {
            url += `&agentId=${agent.id}`
        }
        if (!!agentIdTransfer) {
            url += `&agentIdTransfer=${agentIdTransfer}`
        }
        if (!!filtersData) {
            const {
                customerName,
                companyId,
                status,
                agentId,
                documentId,
                minDaysPastDue,
                maxDaysPastDue,
                postponedPayment,
                accountExecutiveId,
                maxDebt,
                minDebt,
            } = filtersData
            if (!!customerName) {
                url += `&customerName=${customerName}`
            }
            if (!!companyId) {
                url += `&companyId=${companyId}`
            }
            if (!!status) {
                url += `&status=${status}`
            }
            if (!!agentId) {
                url += `&agentId=${agentId}`
            }
            if (!!documentId) {
                url += `&documentId=${documentId}`
            }
            if (!!minDaysPastDue) {
                url += `&minDaysPastDue=${minDaysPastDue}`
            }
            if (!!maxDaysPastDue) {
                url += `&maxDaysPastDue=${maxDaysPastDue}`
            }
            if (!!postponedPayment) {
                url += `&postponedPaymentReason=${postponedPayment}`
            }
            if (!!accountExecutiveId) {
                url += `&accountExecutiveId=${accountExecutiveId}`
            }
            if (!!minDebt) {
                url += `&minDebt=${minDebt}`
            }
            if (!!maxDebt) {
                url += `&maxDebt=${maxDebt}`
            }
        }
    }
    try {
        await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
        message.success('El ticket ha sido transferido exitosamente')
    } catch (error) {
        message.error('Ha ocurrido un error al transferir el ticket')
    }
}

export const transferTicket = (ticketId, agentId, token) => async (dispatch) => {
    try {
        await axios.get(
            `${XONE_CASH_API}/v1/tickets/transferTicket?ticketId=${ticketId}&agentId=${agentId}`,
            {headers: {'Authorization': 'Bearer ' + token}},
        )
        message.success('El ticket ha sido transferido exitosamente')
    } catch (error) {
        message.error('Ha ocurrido un error al transferir el ticket')
    }
}

function timeout(delay) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

export const getCatalogItems = (token, catalogName, companyId = null, searchItem = null) => async (dispatch) => {
    let catalog
    let url = `${XONE_CASH_API}/v1/tickets/catalog/find/${catalogName}`
    if (!!companyId) {
        url += `/${companyId}`
    }
    if (!!searchItem) {
        url += `/${searchItem}`
    }
    if (catalogName === 'retraso_de_pago') {
        catalog = PAYMENT_DELAY_CATALOG
    } else if (catalogName === 'pago_postergado') {
        catalog = PAYMENT_POSTPONED_CATALOG
    } else if (catalogName === 'status') {
        catalog = TICKET_STATUS_CATALOG
    } else {
        catalog = GET_CATALOG_ITEMS
    }
    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: catalog, payload: data || []})
}

export const resetCatalogItems = (catalogName) => async (dispatch) => {
    let catalog
    if (catalogName === 'retraso_de_pago') {
        catalog = PAYMENT_DELAY_CATALOG
    } else if (catalogName === 'pago_postergado') {
        catalog = PAYMENT_POSTPONED_CATALOG
    } else if (catalogName === 'status') {
        catalog = TICKET_STATUS_CATALOG
    } else {
        catalog = GET_CATALOG_ITEMS
    }
    dispatch({type: catalog, payload: []})
}

export const saveCatalogItem = (item, token) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/catalog/`
    try {
        if (!!item.id) {
            url += `edit/${item.id}`
            await axios.put(url, {
                catalog: item.catalog,
                value: item.value,
                ticketCompany: item.ticketCompany,
            }, {headers: {'Authorization': 'Bearer ' + token}})
        } else {
            url += 'create'
            await axios.post(url, {
                catalog: item.catalog,
                value: item.value,
                ticketCompany: item.ticketCompany,
            }, {headers: {'Authorization': 'Bearer ' + token}})
        }
        dispatch(getCatalogItems(token, item.catalog, item.ticketCompany))
        message.success('El motivo se guardó en el catálogo exitosamente')
    } catch (error) {
        message.error('Ha ocurrido un error al guardar el motivo en el catálogo')
    }
}

export const deleteCatalogItem = (itemId, catalog, token, companyId) => async (dispatch) => {
    try {
        await axios.delete(`${XONE_CASH_API}/v1/tickets/catalog/delete/${itemId}`, {headers: {'Authorization': 'Bearer ' + token}})
        dispatch(getCatalogItems(token, catalog, companyId))
        message.success('El motivo se eliminó del catálogo exitosamente')
    } catch (error) {
        message.error('Ha ocurrido un error al eliminar el motivo en el catálogo')
    }
}

export const getAgentsMonitoring = (token, filters = null) => async (dispatch) => {
    let url = `${XONE_CASH_API}/v1/tickets/getAgentsMonitoring`
    if (!!filters) {
        if (!!filters.agentId) {
            url += `?agentId=${filters.agentId}`
        }
        if (!!filters.companyId) {
            if (!!filters.agentId) {
                url += `&companyId=${filters.companyId}`
            } else {
                url += `?companyId=${filters.companyId}`
            }
        }
    }
    const {data} = await axios.get(url, {headers: {'Authorization': 'Bearer ' + token}})
    dispatch({type: GET_AGENTS_MONITORING, payload: data || []})
}

export const getManagementCollectionsReport = (token, filters) => async (dispatch) => {
    var url = `${XONE_CASH_API}/v1/tickets/managementCollections`
    if (!!filters) {
        if (!!filters.agentId) {
            url += `?agentId=${filters.agentId}`
        }
        if (!!filters.companyId) {
            if (!!filters.agentId) {
                url += `&companyId=${filters.companyId}`
            } else {
                url += `?companyId=${filters.companyId}`
            }
        }
        if (!!filters.statusTicket) {
            if (!!filters.agentId || !!filters.companyId) {
                url += `&statusTicket=${filters.statusTicket}`
            } else {
                url += `?statusTicket=${filters.statusTicket}`
            }
        }
        if (!!filters.filterName) {
            if (!!filters.agentId || !!filters.companyId || !!filters.statusTicket) {
                url += `&filterName=${filters.filterName}`
            } else {
                url += `?filterName=${filters.filterName}`
            }
        }
        if (!!filters.period) {
            if (!!filters.agentId || !!filters.companyId || !!filters.statusTicket || !!filters.filterName) {
                url += `&from=${moment(filters.period[0]).format('YYYY-MM-DD')}&to=${moment(filters.period[1]).format('YYYY-MM-DD')}`
            } else {
                url += `?from=${moment(filters.period[0]).format('YYYY-MM-DD')}&to=${moment(filters.period[1]).format('YYYY-MM-DD')}`
            }
        }
    }
    try {
        message.info('Generando reporte, por favor espere...')
        const {data, status} = await axios.get(url, {
            headers: {'Authorization': 'Bearer ' + token},
            responseType: 'blob', // important
        })
        dispatch({type: MANAGEMENT_COLLECTIONS_REPORT, payload: status === 200 ? data : null})
    } catch (error) {
        message.error('Se ha producido un error al generar el reporte')
    }
}

export const setFilters = (filters) => async (dispatch) => {
    dispatch({type: SET_FILTERS, payload: filters})
}
export const setCompany = (filters) => async (dispatch) => {
    dispatch({type: SET_COMPANY, payload: filters})
}

export const clearManagementCollectionsReport = () => (dispatch) => {
    dispatch({type: MANAGEMENT_COLLECTIONS_REPORT, payload: []})
}

export const setLoader = (isLoad) => (dispatch) => {
    dispatch({type: LOADER_TICKET, payload: isLoad})
}

